import React, { FC, useEffect, useState } from 'react'
import NextLink from 'next/link'
import {
  Stack,
  Box,
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  Link,
  PopoverContent,
} from '@chakra-ui/react'
import { MenuItemDesktop } from 'src/components'

/** https://github.com/chakra-ui/chakra-ui/issues/5896 */
export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger

interface Props {
  items: MenuGlobal.MenuItem[]
}

const Desktop: FC<Props> = ({ items }) => {
  const [isMounted, setMount] = useState(false)

  useEffect(() => {
    setMount(true);
  }, [])

  return <Stack direction="row" spacing={4} align="center">
    {items.map(navItem => (
      <Box key={navItem.name} textAlign="left" className={!isMounted && navItem.children && navItem.children.length > 0 ? 'has-popover' : ''}>
        {navItem.children && navItem.children.length > 0 ?
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
            <NextLink href={navItem.href} passHref>
              <Link
                // href={navItem.href}
                target={navItem.target}
                whiteSpace="nowrap"
                color="baseNeutral.700"
                fontFamily="Montserrat-SemiBold"
                fontSize="0.875rem"
                textAlign="left"
                letterSpacing="0.25px"
                textTransform="uppercase"
                _hover={{
                  textDecoration: 'none',
                  color: 'primaryGreen.800',
                }}
                dangerouslySetInnerHTML={{
                  __html: navItem.name,
                }}
              />
              </NextLink>
            </PopoverTrigger>
            <PopoverContent border={0} boxShadow="xl" bg="baseWhite" p={4} rounded="xl" minW="sm">
              <Stack>
                {navItem.children.map(child => (
                  <MenuItemDesktop key={child.name} {...child} />
                ))}
              </Stack>
            </PopoverContent>
          </Popover>
          :
          <NextLink href={navItem.href} passHref>
          <Link
            target={navItem.target}
            whiteSpace="nowrap"
            color="baseNeutral.700"
            fontFamily="Montserrat-SemiBold"
            fontSize="0.875rem"
            letterSpacing="0.25px"
            textTransform="uppercase"
            _hover={{
              textDecoration: 'none',
              color: 'primaryGreen.800',
            }}
            // href={navItem.href}
            dangerouslySetInnerHTML={{
              __html: navItem.name,
            }}
          />
          </NextLink>
        }
      </Box>
    ))}
  </Stack>
}

export default Desktop
